import styled from 'styled-components';
import { ContainerColumnStart } from '../../styles/Common';
import { deviceMax } from '../../styles/MediaQuery';
import { blue, containerWidth, darkGreen, greyBorder, linkOnHover } from '../../styles/Variables';

export const Container = styled(ContainerColumnStart)`
    margin: 0.5rem auto;

    @media ${deviceMax.tabletXl} {
        width: calc(100% - 30px);
    }
`;

export const BioWrapper = styled.div`
    margin: 1rem 0;
    word-break: break-word;
`;

export const Title = styled.h1`
    font-family: 'NeueHelveticaPro77CondensedBold', Arial, Helvetica;
    color: ${blue};
    font-size: 2.5rem;
    margin-bottom: 0rem;
    line-height: 2.75rem;
    letter-spacing: 0.05em;
    word-break: break-word;

    @media ${deviceMax.tablet} {
        font-size: 1.75rem;
    }
`;

export const PersonWrapper = styled.div`
    width: ${containerWidth};
    display: grid;
    grid-template-columns: 160px 1fr 1fr 1fr;
    height: 80px;
    margin: 0 auto;
    border-top: 1px solid ${greyBorder};
    border-bottom: 1px solid ${greyBorder};

    @media ${deviceMax.tabletXl} {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: unset;
        padding-bottom: 0.5rem;
    }

    * {
        font-family: Arial, Helvetica, sans-serif;
    }
`;

export const DataBox = styled.div`
    margin: 0;
    padding: 0;
`;

export const DataFieldText = styled.p`
    font-size: 0.875rem;

    @media ${deviceMax.tabletXl} {
        margin: 0.5rem 0 0 0;
    }
`;

export const DataFieldLink = styled.a`
    font-size: 0.875rem;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
    color: ${darkGreen};

    :hover {
        color: ${linkOnHover};
        text-decoration: none;
    }
`;

export const PersonImage = styled.img`
    width: 140px;
    height: 80px;
    object-fit: cover;

    @media ${deviceMax.tabletXl} {
        width: 100%;
        height: unset;
    }
`;

export const FieldPosition = styled.div`
    margin-top: 0.5rem;
    font-family: Arial, Helvetica, sans-serif;
`;

export const FieldName = styled.p`
    font-weight: 700;
    margin: 1rem 0 0 0;
`;
